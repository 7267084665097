<template>
  <div class="orders-table orders-history-table">
    <div class="row pt-5">
      <div class="col-lg-6 py-3 d-flex align-items-center">
        <h3>Delivered Orders</h3>
        <button
          @click="updateOrders"
          class="create-button"
          :class="fetchingRecords ? 'btn-disabled processing' : ''"
          :disabled="fetchingRecords"
        >
          Refresh
          <img
            class="ajax-loader"
            src="/media/ajax/white.gif"
            alt="Ajax loader"
          />
        </button>
      </div>
      <div class="col-lg-3">
        <div style="display: grid; place-items: center" class="w-100 h-100">
          <span><b>Total Records:</b> {{ rows }}</span>
        </div>
      </div>
      <div
        class="col-lg-3 d-flex align-items-center justify-content-end py-3"
        v-if="rows > 10"
      >
        <div class="row w-100">
          <span class="col-4 d-flex align-items-center">Orders Per Page</span>
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8"
            @change="perPageChange"
          ></b-form-select>
        </div>
      </div>
      <div class="col-lg-12 table-container">
        <template v-if="true">
          <b-table
            id="active-orders-table"
            hover
            :items="tableOrders"
            :fields="fields"
            :busy="fetchingRecords"
            class="table-light"
            thead-class="customers-table-head shadow-sm"
            tbody-class="active-orders-table-body"
            @row-clicked="orderRowClicked"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mx-4"></b-spinner>
                <strong>Fetching...</strong>
              </div>
            </template>
            <template v-slot:cell(type)="data">
              <div
                class="data-cell type"
                v-bind:class="getTypeClass(data.value)"
              >
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(timePlaced)="data">
              <span> {{ $moment(data.value).format("MMM D, h:mm a") }}</span>
            </template>
            <template v-slot:cell(customer)="data">
              <span style="display: flex; width: 100%" class="name-data-block">
                <template v-if="Array.isArray(data.item.customer)">
                  <stacked-images
                    :images="data.item.customer"
                    type="order"
                    :tipId="`${data.item.order}-${data.item.index}`"
                  />
                </template>
                <template v-else>
                  <span class="image-block">
                    <img
                      :src="
                        `${data.item.customer.profile ||
                          '/media/users/user1.jpg'}`
                      "
                    />
                  </span>
                  <span
                    style="display: flex; flex-direction: column"
                    class="details-block"
                  >
                    <span class="user-name">{{ data.item.customer.name }}</span>
                    <span class="address">{{
                      data.item.customer.address
                    }}</span>
                    <span class="phone-number">{{
                      data.item.customer.phone
                    }}</span>
                  </span>
                </template>
              </span>
            </template>

            <template v-slot:cell(orderAmount)="data">
              <div class="data-cell order-amount">
                <span class="value">{{ data.value }}</span>
              </div>
            </template>
            <template v-slot:cell(Number_of_Retries)="data">
              <div class="data-cell order-amount">
                <span
                  :class="`${data.value === 0 ? 'green' : 'red'}`"
                  class="value"
                  >{{ data.value }}
                  <b-dropdown v-if="data.value > 0" id="dropdown-1">
                    <b-dropdown-item
                      v-for="(rider, index) in activeOrders[data.index].retries"
                      :key="index"
                      >{{ rider.rider.name }}</b-dropdown-item
                    >
                  </b-dropdown></span
                >
                >
              </div>
            </template>
            <template v-slot:cell(Status)="data">
              <div class="data-cell status-cell" v-if="data.value === 'Good'">
                <img src="/media/dashboard/green_smiley.png" alt="Confirmed" />
              </div>
              <div
                class="data-cell status-cell"
                v-else-if="data.value === 'Late' || data.value == 'Very Late'"
              >
                <img src="/media/dashboard/red_exclamation.png" alt="Pending" />
              </div>
              <div class="data-cell status-cell" v-else>
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(vendors)="data">
              <stacked-images
                :images="data.value"
                :tipId="`${data.item.order}-stacks-${data.item.index}`"
                type="order"
              />
            </template>

            <template v-slot:cell()="data">
              <div class="data-cell">{{ data.value }}</div>
            </template>
          </b-table>
          <div class="col-lg-12 d-flex justify-content-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="changePage"
              aria-controls="active-orders-table"
              :current-page="currentPage"
            ></b-pagination>
          </div>
        </template>
        <template v-else>
          <div class="text-danger text-center display-4 p-4">API Error</div>
          <div class="text-danger text-center lead pb-4">
            Error while fetchting active orders. Please refresh to try again.
          </div>
          <div class="text-danger text-center lead pb-4">
            {{ networkError }}
          </div>
        </template>
      </div>
    </div>
    <template v-if="modalData !== null">
      <order-modal
        ref="orderModal"
        :orderDetails="modalData"
        @orderModalClosed="orderModalClosed"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StackedImages from "@/view/layout/dashboard_components/StackedImages";
import OrderModal from "@/view/layout/dashboard_components/OrderModal";

export default {
  props: {
    orderCall: String
  },
  name: "OrdersTableUS",
  components: {
    "stacked-images": StackedImages,
    "order-modal": OrderModal
  },
  data: () => ({
    acticeOrderindex: null,
    storeNamesToll: "",
    fetchingStats: true,
    modalData: null,
    perPage: 10,
    currentPage: 1,
    fields: [
      {
        key: "type",
        label: "Type",
        sortable: false
      },
      {
        key: "order",
        label: "Order",
        sortable: true
      },
      {
        key: "customer",
        label: "Customer",
        sortable: true
      },
      {
        key: "zipCode",
        label: "Zip Code",
        sortable: true
      },
      {
        key: "timePlaced",
        label: "Order Time",
        sortable: true
      },
      {
        key: "vendors",
        label: "Vendors",
        sortable: true
      },
      {
        key: "rider",
        label: "Rider",
        sortable: true
      },
      {
        key: "retries",
        label: "Retries",
        sortable: true
      },
      {
        key: "orderAmount",
        label: "Order Amount",
        sortable: true
      },
      {
        key: "performance",
        label: "Performance",
        sortable: true
      }
    ],
    perPageOptions: [
      { value: 10, text: "10" },
      { value: 25, text: "25" },
      { value: 50, text: "50" }
    ]
  }),
  computed: {
    ...mapState({
      region: ({ region }) => region.region,
      orders: ({ orderHistory }) => orderHistory.orders,
      fetchingRecords: ({ orderHistory }) => orderHistory.fetching,
      networkError: function(state) {
        return state.orders.ordersError;
      },
      tableOrders: ({ orderHistory }) => {
        return orderHistory.orders.map((item, index) => {
          const { orderModel, order, rider } = item;
          let squadOrder = null;
          if (orderModel === "squad") {
            squadOrder = order.deliveryRoute.find(s => s.rider._id === rider);
            console.log(
              "file: OrdersTable.vue | line 300 | returnactiveOrders.orders.map | squadOrder",
              squadOrder
            );
          }
          return {
            type: item.orderModel,
            order: orderModel === "squad" ? order.inviteId : order.orderId,
            customer:
              orderModel === "squad"
                ? squadOrder.customers.map(c => ({
                    name: c.customer.name,
                    image: c.customer.profileImage
                  }))
                : {
                    name: order.customer.name,
                    image: order.customer.profileImage,
                    address: order.shippingAddress.address,
                    phone: order.customer.phone
                  },
            zipCode: order.zipCode,
            timePlaced:
              orderModel === "squad" ? squadOrder.timeFound : order.timePlaced,
            retries: [],
            vendors:
              orderModel === "squad"
                ? [
                    {
                      image: order.store.storeLogo,
                      name: order.store.storeName
                    }
                  ]
                : order.stores.map(s => ({ image: s.image, name: s.name })),
            rider:
              orderModel === "squad"
                ? squadOrder.rider.name
                : order.riderId.name,
            orderAmount:
              orderModel === "squad"
                ? `$${squadOrder.estimatedAmount}`
                : `$${order.estimatedAmount}`,
            performance: "Good",
            index
          };
        });
      },
      rows: state => state.orders.historyOrdersCount
    })
  },
  mounted() {
    this.$store.dispatch(this.orderCall, [0, 10]);
  },
  methods: {
    updateOrders: function() {
      this.$store.dispatch(this.orderCall, [
        (this.currentPage - 1) * this.perPage,
        this.perPage
      ]);
    },
    perPageChange(val) {
      this.$store.dispatch(this.orderCall, [(this.currentPage - 1) * val, val]);
    },
    changePage(val) {
      this.$store.dispatch(this.orderCall, [
        (val - 1) * this.perPage,
        this.perPage
      ]);
    },
    orderRowClicked(items) {
      const { orderModel, order, rider } = this.orders[items.index];
      if (orderModel === "squad") {
        const squadOrder = order.deliveryRoute.find(s => s.rider._id === rider);
        const infos = order.deliveryInfo.filter(s => s.riderId === rider);
        this.modalDataSquad = {
          ...squadOrder,
          store: order.store,
          deliveryInfo: infos,
          product: order.product,
          squadDiscount: order.squadDiscount,
          inviteId: order.inviteId
        };

        setTimeout(() => {
          this.$refs.orderModalSquad.showOrderModal();
        }, 100);
      } else {
        (this.modalData = this.orders[items.index].order),
          setTimeout(() => {
            this.$refs.orderModal.showOrderModal();
          }, 100);
      }
    },
    orderModalClosed() {
      this.orderModal = null;
    },
    getTypeClass(type) {
      if (type === "squad") {
        return "squad-type";
      }
      return "normal-type";
    }
  }
};
</script>

<style lang="scss">
.orders-table {
  ::v-deep .customers-table-head {
    background: #fff;
    tr {
      th {
        vertical-align: middle;
        color: #763fb8;
        &:nth-child(3) {
          width: 30%;
        }
      }
    }
  }
  tr {
    vertical-align: middle !important;
    &:hover {
      background: #763fb8;
      color: #fff;
    }
    td {
      vertical-align: middle !important;
      font-size: 12px;
      .data-cell {
        display: flex;
        align-items: center;
        &.order-amount {
          display: flex;
          justify-content: center;
          width: 100%;
          .value {
            background: #82f89d;
            border-radius: 25px;
            padding: 3px;
            font-weight: 700;
            color: #019b25;
            width: 75px;
            text-align: center;
          }
          .green {
            background: #82f89d;
            color: white;
          }
          .red {
            background: #dc18288a;
            color: white;
          }
          .dropdown-toggle {
            background-color: transparent !important;
            border: none !important;
            padding: 0px !important;
            color: white;
          }
        }
        &.type {
          padding: 3px;
          border-radius: 25px;
          font-weight: 700;
          display: flex;
          justify-content: center;
          width: 100%;
        }
        &.type.normal-type {
          background: #ffafb1;
          color: #e90008;
        }
        &.type.squad-type {
          background: #fff27b;
          color: #809900;
        }
        &.status-cell {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 20px;
            width: auto;
          }
        }
      }
    }
    td:nth-child(3) {
      width: 33%;
      .name-data-block {
        width: 100%;
        .image-block {
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          overflow: hidden;
          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }
        }
        .details-block {
          width: 85%;
          .user-name {
            padding: 2.5px 0;
            font-weight: 700;
          }
          .address {
            color: black;
          }
          .phone-number {
            color: black;
          }
        }
      }
    }
  }
}
</style>
